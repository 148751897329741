@import url('https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap');

*{
  color: #FFFFF0;
  background-color: #56C7D3;
  overflow-x: hidden;
  text-align: center;
  font-family: "Chelsea Market";
  font-size: 1rem;
}

.ContactFormLineOne{
  margin-top: 20px;
}

.InputField{
  border-radius: 10px;
  color: #56C7D3;
  margin-right: 10px;
  height: 30px;
  background-color: #D6F7FF;
  border : 2px solid #D6F7FF;
}

.Content{
  margin-bottom: 50px;
}

.ServicesHeader{
  font-size: x-large;
  font-weight: bold;
  padding-top: 20px;
}

.Inline{
  margin: 10px;
}

.ContactFormHeader{
  font-size: larger;
  margin-top: 20px;
}

.ContactFormInputField{
  border-radius: 10px;
  color: #56C7D3;
  width: 90%;
  height: 200px;
  margin-top: 10px;
  padding: 10px;
  background-color: #D6F7FF;
  border : 2px solid #D6F7FF;
}

.ContactFormLine{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
}

.ContactFormContainer{
  margin-top: 30px;
  width: fit-content;
  border-radius: 10px;
  max-width: 90vw;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border : 3px solid #D6F7FF;
}

.ContactFormContainer:focus{
  background-color: red;
}

.ConsultationHeader{
  margin: 20px;
}

.ContactFormSendEmailButton{
  margin: 20px;
  background-color: #0B7581;
  border : 2px solid #0B7581;
  border-radius: 40px;
  width: 350px;
  height: 100px;
  cursor: pointer;
}

button:hover{
  background-color: #D6F7FF;
  border : 2px solid #D6F7FF;
  color: #56C7D3;
}

.ConsultationRequestButton{
  font-size: x-large;
  background-color: #0B7581;
  margin: 20px;
  border : 2px solid #0B7581;
  border-radius: 40px;
  width: 350px;
  height: 100px;
  cursor: pointer;
}

.ConsultationRequestButton:hover{
  background-color: #D6F7FF;
  border : 2px solid #D6F7FF;
  color: #56C7D3 ;
}

.Header{
  background-color: #0B7581;
  cursor: pointer;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: x-large;
}

.HeaderSalishSparkle{
  font-size: x-large;
  background-color: inherit;
  font-weight: bold;
  margin-bottom: 3px;
}

.HeaderPhoneNumber{
  background-color: inherit;
  font-size: small;
  text-decoration: none;
}

.HeaderMotto{
  background-color: inherit;
  font-size: medium
}

.AboutHeader{
  font-size: x-large;
}

.AboutContainer{
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AboutContent{
  font-size: large;
  width: 900px;
  max-width: 90vw;
  margin-bottom: 20px;
}

.ServiceCatergory{
  background-color: inherit;
}

.ServicesContainer{
  background-color: inherit;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ServicesFlexbox{
  display: flex;
  flex-direction: row;
}

.ServiceContainer{
  font-size: x-large;
  background-color: #0B7581;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  cursor: pointer;
  width : 250px;
  text-align: center;
  border : 2px solid #0B7581;
  padding : 50px;
  margin : 20px;
}

.ServiceContainer:hover{
  background-color: #D6F7FF;
  border : 2px solid #D6F7FF;
  color: #56C7D3;
}

.ServicesText{
  
  background-color: inherit;
  color:inherit;
}

.FooterContainer{
  bottom: 0px;
  background-color: #0B7581;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: smaller;
}

.FooterContent{
  background-color: inherit;
}

.ImageSlideshowContainer{
  width: 100%;
  min-width: 100vw;
  height: fit-content;
  padding-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow:hidden;
  align-items: center;
}

.ImageSlideshowOverlay{
  overflow: visible;
  width: 300px;
  height: fit-content;
  position:absolute;
  left : 50%;
  transform:translate(-50%, -600%);
  font-size: small;
  background-color:rgba(255,0,0,0);
}

.ConsultationButtonContainer{
  overflow: visible;
}

.ConsultationButton{
  color: #D6F7FF;
  background-color: #0B7581;
  font-size: x-large;
  border-radius: 50px;
  border : 2px solid #0B7581;
  cursor: pointer;
  position:absolute;
  width: 250px;
  height: 75px;
  left : 50%;
  transform:translate(-50%, +145%);
  top: 0px;
  z-index: 1;
}

.ConsultationButtonInline{
  color: #D6F7FF;
  background-color: #0B7581;
  font-size: x-large;
  border-radius: 50px;
  border : 2px solid #0B7581;
  cursor: pointer;
  width: 250px;
  height: 75px;
  margin: 10px;
  z-index: 1;
}

.ConsultationButton:hover{
  background-color: #D6F7FF;
  color: #56C7D3;
  border : 2px solid #D6F7FF;
}

.SlideshowImage{
  flex-shrink: 0;
  height : 300px;
  width: 300px;
}

.HomeCleaningPageContainer{
  display: flex;
  flex-direction: column;
}

.FlexboxRow{
  padding: 0px;
  margin-left: 20px;
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 50%;
  transform:translate(-50%, +0%);
  position: relative;
}

@media only screen and (max-width: 700px) {
  .ServicesFlexbox{
    flex-direction: column;
  }

  .FlexboxRow{
    margin-left: 30px;
    flex-direction: column;
  }
}

.ServiceCatergory{
  padding-bottom: 10px;
  margin-right: 60px;
  color: #f7e99e;
  font-size: larger;
  font-weight: bold;
}

.ServicesOffered{
  background-color: inherit;
  font-size: x-large;
  padding-bottom: 10px;
  
}

.ServicesListItemSubtext{
  background-color: inherit;
  padding-right:60px;
  font-size: medium;
}

ul{
  background-color: inherit;
}

.ServicesListItem{
  background-color: inherit;
  color: #f7e99e;
  font-size: large;
}

.ErrorMessageContainer{
  display: flex;
  flex-direction: column;
}

.ErrorMessage{
  font-size: small;
  color: rgb(216, 68, 68);
}

.ServiceContainerCateg{
  background-color: #1AA7B6;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 20px;
}

.flexboxColumn{
  background-color: #0B7581;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

li{
  text-align: left;
}

ul{
  padding-left: 20px;
  margin-right: 30px;
}